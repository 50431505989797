// import LandingDbSource from "../../data/landingdb-source";
import { content1Page } from "../component/forgotPassword-creator";

const forgotPasswordPage= {
  async render() {
    return `
    <div class="content textPutih content-forgot-password" tabindex="0">
      <section id="content1" class="content1"></section>
    </div>
    `;
  },

  async afterRender() {
    document.title = "FORGOT PASSWORD";
    try {
      /*
        content 1
      */
      //   const content1Source = await LandingDbSource.LandingAct();
      const content1Container = document.querySelector("#content1");
      content1Container.innerHTML = content1Page();

    } catch (error) {
      console.log(error);
    }
  },
};
// content3Source.forEach((kocaks) => {
//   content3Container.innerHTML += content3Page(kocaks);
// });
export default forgotPasswordPage;
