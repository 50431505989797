import API_ENDPOINT from "../../globals/api-endpoint";
import { content1Page } from "../component/login-creator";

const Login = {
  async render() {
    return `
      <div class="content textPutih content-login" tabindex="0">
        <section id="content1" class="content1"></section>
      </div>
    `;
  },

  async afterRender() {
    document.title = "LOGIN";
    try {
      // Menambahkan konten login pada halaman
      const content1Container = document.querySelector("#content1");
      content1Container.innerHTML = content1Page();

      const passwordField = document.getElementById("password");
      const togglePassword = document.getElementById("toggle-password");

      togglePassword.addEventListener("click", () => {
        // Memastikan passwordField sudah didefinisikan
        const type = passwordField.type === "password" ? "text" : "password";
        passwordField.type = type;
        togglePassword.querySelector("i").classList.toggle("bi-eye-slash");
      });
      // Menangani form submission
      const form = document.getElementById("login-form");
      if (form) {
        form.addEventListener("submit", async (event) => {
          event.preventDefault(); // Mencegah form default submission

          const email = document.getElementById("email").value;
          const password = document.getElementById("password").value;

          // Mengirim request login ke server
          try {
            const response = await fetch(API_ENDPOINT.LOGIN, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            if (data.error) {
              alert(data.message); // Menampilkan pesan error jika login gagal
            } else {
              // Menyimpan token dan user di localStorage
              localStorage.setItem("token", data.token);
              localStorage.setItem("user", JSON.stringify(data.user));

              // Mengarahkan pengguna berdasarkan role
              if (data.user.role === "admin") {
                window.location.href = "#/admin/dashboard"; // Arahkan ke halaman admin
              } else if (data.user.role === "user") {
                window.location.href = "#/user/dashboard"; // Arahkan ke halaman user
              } else {
                window.location.href = "#/"; // Arahkan ke halaman default jika role tidak diketahui
              }
              window.location.reload();
            }
          } catch (error) {
            console.error("Login failed:", error);
            alert("Terjadi kesalahan. Silakan coba lagi.");
          }
        });
      }

    } catch (error) {
      console.error("Error rendering login page:", error);
    }
  },
};

export default Login;
