import LandingDbSource from "../../data/landingdb-source";
import {
  content1Page,
  content2Page,
  content3Page,
  content4Page,
  content5Page,
  content6Page,
  content7Page,
} from "../component/landing/home-creator";

const landing = {
  async render() {
    return `
    <div class="content textPutih content-landing" tabindex="0">
      <section id="content1" class="content1"></section>
      <section id="content2" class="content2"></section>
      <section id="content3" class="content3"></section>
      <section id="content4" class="content4"></section>
      <section id="content5" class="content5"></section>
      <section id="content6" class="content6"></section>
      <section id="content7" class="content7"></section>
    </div>
    `;
  },

  async afterRender() {
    try {
      /*
        content 1
      */
      const content1Container = document.querySelector("#content1");
      content1Container.innerHTML = content1Page();

      /*
        content 2
      */
      const content2Source = await LandingDbSource.LandingCrew();
      const landingContainer = document.querySelector(".content2");
      // Karena satu carousel dengan multiple slides, kita tidak perlu forEach
      landingContainer.innerHTML = content2Page(content2Source);
      const carousel = new bootstrap.Carousel(
        document.querySelector("#myCarousel"),
        {
          interval: 3000,
          wrap: true,
        }
      );

      /*
        content 3
      */
      const content3Source = await LandingDbSource.LandingAct();
      // console.log("content3Source : ", content3Source); 
      const content3Container = document.querySelector("#content3");
      // content3Container.innerHTML = content3Page(content3Source);
      // Mengambil 3 data terbaru dari array
      const latestContent3 = content3Source.slice(0, 3);
      content3Container.innerHTML = content3Page(latestContent3);

      /*
        content 4
      */
      const content4Container = document.querySelector("#content4");
      content4Container.innerHTML = content4Page();

      /*
        content 5
      */
      const content5Source = await LandingDbSource.LandingMP();
      const content5Container = document.querySelector("#content5");
      content5Container.innerHTML = content5Page(content5Source);

      /*
        content 6
      */
      const content6Source = await LandingDbSource.LandingSP();
      const content6Container = document.querySelector("#content6");
      content6Container.innerHTML = content6Page(content6Source);

      /*
        content 7
      */
      const content7Container = document.querySelector("#content7");
      content7Container.innerHTML = content7Page();

    } catch (error) {
      console.log(error);
    }
  },
};
// content3Source.forEach((kocaks) => {
//   content3Container.innerHTML += content3Page(kocaks);
// });
export default landing;
